

.footer {
    height: 100px;
    background-color: rgb(34, 34, 34);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.footer-text > a {
    font-family: 'Calibri', 'Trebuchet MS';
    font-size: 15px;
    color: white;
    /* color: inherit; */
    text-decoration:underline;
    color: white;
}

.footer > p {
    font-family: 'Calibri', 'Trebuchet MS';
    font-size: 15px;
    color: white;
    /* color: inherit; */
    text-decoration: none;
    color: white;
}

.footer > a {
    
}