
.projectshowcase {
    font-family: 'Calibri', 'Trebuchet MS';
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.projectshowcase-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 1000px;
}

.projectshowcase-description > p {
    flex-basis: 70%;
    max-width: 600px;
    margin-right: 40px;
    margin-left: 20px;
    text-align: left;
}

.projectshowcase-technologies {
    font-style: italic;
    color: gray;
}

.projectshowcase-description > h1 {
    flex-basis: 70%;
    max-width: 600px;
    margin-left: 20px;
    text-align: left;
}

.projectshowcase-title {
    color: rgb(34, 34, 34);
    text-decoration: none;
}

.projectshowcase-title:hover {
    color: rgb(34, 34, 34);
    text-decoration: underline;
}

.crop {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 20px;
    border-radius: 15px;
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;

    border-width: 0px;
    border-color: rgb(34, 34, 34);
    border-style: solid;

    transition: border-width 0.1s ease-in-out;
}

.crop:hover {
    border-width: 3px;
  }

.crop img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

@media (max-width: 700px) {
    .crop {
      display: none;
    }
  }