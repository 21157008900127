
.separator {
    height: 100px;
    background-color: rgb(34, 34, 34);
    color: white;
    font-family: 'consolas', 'Courier';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;

}


.separator-container {
    max-width: 1000px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}