
.homesection {
    font-family: 'Calibri', 'Trebuchet MS';
    letter-spacing: 0.1em;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 200px;
    color: rgb(34, 34, 34)

}

.homesection-info-container > h1 {
    font-size: 50px;
}

.copyright {
    font-size: 15px;
}

.homesection-info-container {
    font-size: 20px;
}

.homesection-contact-info {
    font-size: 1.3em;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}

.fa-enveloppe:before {
    background-color: black;
    
}

.homesection-contact-info > a {
    color: inherit;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    transition: background-color 0.1s ease-in-out;
}


.homesection-contact-info > a:hover {
    background-color: rgb(34, 34, 34, 0.3);
}


